import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify`
export const LOGIN_URL = `${API_URL}/login`

export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
  })
}

export function getUserByToken(auth: AuthModel) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, auth)
}
