import {ID, Response} from '../../../../../../../_metronic/helpers'
export type User = {
  id?: ID
  username?: string
  avatar?: string | "avatars/avatar.gif";
  rights?: number
  createdAt?: number
}

export type UsersQueryResponse = Response<Array<User>>

export const initialUser: User = {
  avatar: 'avatars/avatar.gif',
  rights: 0,
  username: '',
}

export const mapRightsToString = (rights: number): string => {
  switch (rights) {
    case 0:
      return "Moderator";
    case 1:
      return "Administrator";
    case 2:
      return "Developer";
    case 3:
      return "Manager";
    default:
      return "Unknown"; // You can handle other cases as needed
  }
};
